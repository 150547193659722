import React from "react"
import { FaUserSecret, FaSketch } from "react-icons/fa"
import { GiEagleEmblem } from "react-icons/gi"
export default [
  {
    id: 1,
    icon: <FaUserSecret className="service-icon" />,
    title: "Cybersecurity",
    text: `With over 15 years in practicing Cybersecurity, I am always ready for advisory, implementation,
     workshops and speaking opportunities. I cover a wide range of subjects including E-Commerce Fraud, SIEM, 
     SOAR, Endpoints, DDOS, Honeypots, Phishing, Threat intelligence, dark web and APT development. 
     You can request data feed from my Honeypot network and Early warning system. I have also provided 
     emergency assistance for major Cyberattacks when requested.`,
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: "Cloud Architect",
    text: `Experience with On-prem computing was instrumental in my maximizing value with Cloud Computing since my 
    start in 2009. As an early adopter and pioneer of IoT and DevOps, customers and colleagues have valued my insight.
    I continue to find pathways to further the use of consumption driven economy and scale. Get in touch if you are 
    looking for guidance on best practices, low friction and real world Cloud implementation strategy.`,
  },
  {
    id: 3,
    icon: <GiEagleEmblem className="service-icon" />,
    title: "Fintech and AI",
    text: `CMFAS certified Financial Advisor in various products and Engineer by profession allowed me to take my 
    interest further. I am also Certified by Udacity in 'AI For Trading'. I have continued my journey with Value 
    Investing, Quantitative Finance and Analysis. 
     My focus is in Capital Markets and more recently towards derivatives. Currently, I use Deep 
     Learning for parts of the portfolio management traditionally attributed to Equity Analysts or Quantitative Finance`,
  },
]
